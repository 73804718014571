import cognyAIImage from '../assets/partners/cogny-ai.webp';
import sortServeIImage from '../assets/partners/sofrtserve.webp';
import infoPulseIImage from '../assets/partners/info-pulse.webp';
import digitalAIIImage from '../assets/partners/digital-ai.webp';

export const partnersImages = [
	{ value: 'Infopulse', imageSource: infoPulseIImage },
	{ value: 'Softserve', imageSource: sortServeIImage },
	{ value: 'Cognigy', imageSource: cognyAIImage },
	{ value: 'Digital', imageSource: digitalAIIImage },
];
