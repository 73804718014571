import { classNames } from '../utils/classNames';
import useMediaQuery from '../hooks/useMediaQuery';

import MobileLogo from '../components/MobileLogo';

const HeroSection = () => {
	const isMobile = useMediaQuery('(max-width: 575px)');

	return (
		<section className='max-w-[876px] w-full mb-12 flex flex-col items-center z-20'>
			<MobileLogo className={[isMobile ? 'mt-[68px]' : 'mt-[54px] md:mt-[56px]', 'lg:hidden']} />

			<div
				className={classNames(
					'flex flex-col items-center w-full',

					'mt-16 md:mt-20 lg:mt-[232px] custom:mt-[168px] custom:mb-8 xxl:mb-0 xxl:mt-[232px]',
					'text-[57px] leading-[149px] font-extrabold'
				)}
			>
				<h1
					className={classNames(
						'text-[50px] xs:text-[65px] sm:text-[75px] md:text-[90px] lg:text-[105px] xl:text-[115px] xxl:text-[117px]',
						'mr-[100px] xs:mr-[130px] sm:mr-[180px] md:mr-[280px] xl:ml-16 xxl:ml-6',
						'font-extrabold xl:self-start'
					)}
				>
					AI-First
				</h1>

				<span
					className={classNames(
						'text-[50px] xs:text-[65px] sm:text-[75px] md:text-[90px] lg:text-[100px] xl:text-[110px] xxl:text-[122px]',
						'font-extralight xl:self-end',
						'ml-10 xs:ml-[50px] sm:ml-[100px] md:ml-[200px] xl:mr-16 xxl:mr-0',
						'-mt-24 xs:-mt-20 sm:-mt-18 md:-mt-14 lg:-mt-12'
					)}
				>
					Automation
				</span>
			</div>

			<p
				className={classNames(
					'text-[12px] md:text-[14px] xxl:text-[16px]',
					'leading-[20px] xl:leading-[22px] custom:leading-[23px] xxl:leading-[30px]',
					'font-medium max-w-[230px] md:max-w-[405px] w-full',
					'-mt-4 sm:mt-2 md:mt-10 custom:mt-9 xxl:mt-8 mr-4 md:mr-4'
				)}
			>
				Empowering Businesses with <span className='font-bold'>AI-driven Automation and AI Employees</span> for the
				Exponential Growth
			</p>
		</section>
	);
};

export default HeroSection;
