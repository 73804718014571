import { useEffect } from 'react';

const GAConnector = () => {
	useEffect(() => {
		const script = document.createElement('script');
		script.async = true;
		script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_MEASUREMENT_ID}`;

		script.onload = () => {
			window.dataLayer = window.dataLayer || [];

			function gtag() {
				window.dataLayer.push(arguments);
			}

			gtag('js', new Date());
			gtag('config', process.env.REACT_APP_MEASUREMENT_ID);
		};

		document.head.appendChild(script);
	}, []);

	return null;
};

export default GAConnector;
