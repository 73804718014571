import axios from 'axios';

import { getAssistantID } from '../utils/getAssistantID';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const axiosInstance = axios.create({
	baseURL: BASE_URL,
	headers: {
		Accept: 'application/json',
		'assistant-id': getAssistantID(),
	},
});

export default axiosInstance;
