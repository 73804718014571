import websiteIcon from '../assets/contact/web.svg';
import twitterIcon from '../assets/contact/twitter.svg';
import linkedinIcon from '../assets/contact/linkedin.svg';
import phoneIcon from '../assets/contact/phone.svg';
import addressIcon from '../assets/contact/location.svg';
import whatsAppIcon from '../assets/contact/whatsapp.svg';
import telegramIcon from '../assets/contact/telegram.svg';
import threadsIcon from '../assets/contact/threads.svg';

export const contactReplacements = [
	{
		pattern: /- \*\*Address\*\*:\s*(.+?)(?=\s*-)/g,
		replacement: (address) => `
				<li>
					<a href="https://maps.google.com/?q=${encodeURIComponent(address)}" target="_blank" class="flex items-center gap-2">
						<img loading="lazy" src="${addressIcon}" alt="Address" class="w-4 h-4" /> 
						<span>${address}</span>
					</a>
				</li>`,
	},

	{
		pattern: /- \*\*Phone\*\*:\s*(.+?)(?=\s*-)/g,
		replacement: (phone) => `
			<li> 
				<a href="tel:${phone}" class="flex items-center gap-2">
					<img loading="lazy" src="${phoneIcon}" alt="Phone icon" class="w-4 h-4" /> 
					<span>${phone}</span>
				</a> 
			</li>`,
	},

	{
		pattern: /- \*\*Website\*\*:\s*\[(.+?)\]\((.+?)\)/g,
		replacement: (siteName, siteUrl) => `
			<li> 
				<a href="${siteUrl}" target="_blank" class="flex items-center gap-2">
					<img loading="lazy" src="${websiteIcon}" alt="Website icon" class="w-4 h-4" /> 
					<span>${siteName}</span>
				</a>
			</li>`,
	},

	{
		pattern: /- \*\*LinkedIn\*\*:\s*\[(.+?)\]\((.+?)\)/g,
		replacement: (profileName, profileUrl) => `
			<li> 
				<a href="${profileUrl}" target="_blank" class="flex items-center gap-2">
					<img loading="lazy" src="${linkedinIcon}" alt="LinkedIn icon" class="w-4 h-4" /> 
					<span>${profileName}</span>
				</a>
			</li>`,
	},

	{
		pattern: /- \*\*Twitter \(X\)\*\*:\s*\[(.+?)\]\((.+?)\)/g,
		replacement: (handle, handleUrl) => `
			<li> 
				<a href="${handleUrl}" target="_blank" class="flex items-center gap-2">
					<img loading="lazy" src="${twitterIcon}" alt="Twitter icon" class="w-4 h-4" /> 
					<span>${handle}</span>
				</a> 
			</li>
	`,
	},
];

export const contactContent = {
	img: 'https://genum.ai/img/contacts/8 Contact Us (Contacts).png',
	header: 'Contact Information',
	beforeText: 'For any inquiries or support, you can reach us through the following channels:',

	contacts: [
		{
			name: 'Phone',
			text: '+49 152 14 593 712',
			icon: phoneIcon,
			link: 'tel:+4915214593712',
		},

		{
			name: 'WhatsApp',
			text: '+49 152 14 593 712',
			icon: whatsAppIcon,
			link: 'https://wa.me/4915214593712',
		},

		{
			name: 'Telegram',
			text: '@genum_ai',
			icon: telegramIcon,
			link: 'https://t.me/genum_ai',
		},

		{
			name: 'Website',
			text: 'https://genum.ai',
			icon: websiteIcon,
			link: 'https://genum.ai',
		},

		{
			name: 'LinkedIn',
			text: 'GENUM on LinkedIn',
			icon: linkedinIcon,
			link: 'https://www.linkedin.com/company/genum-ai',
		},

		{
			name: 'X (Twitter)',
			text: '@genum.ai',
			icon: twitterIcon,
			link: 'https://twitter.com/genum.ai',
		},

		{
			name: 'Threads',
			text: '@genum.ai',
			icon: threadsIcon,
			link: 'https://www.threads.net/genum.ai',
		},
	],

	afterText:
		"We are here to assist you with any questions or support you may need regarding our AI-driven solutions and services. Don't hesitate to reach out!",
};
