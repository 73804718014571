import { Fragment } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import useScrollInputVisibility from './hooks/useScrollInputVisibility';
import AppContextProvider from './context/AppContextProvider';

import GAConnector from './components/GAConnector';
import FloatingSidebar from './components/navigation/FloatingSidebar';
import HeroSection from './sections/HeroSection';
import HomeSection from './sections/HomeSection';
import ArticlesLayout from './components/template-cards/ArticlesLayout';
import FormComponent from './components/form/FormComponent';

const queryClient = new QueryClient();

const App = () => {
	const { ref, showInputField } = useScrollInputVisibility();

	return (
		<Fragment>
			{process.env.REACT_APP_DEVELOPMENT_MODE === 'production' && <GAConnector />}

			<QueryClientProvider client={queryClient}>
				<AppContextProvider>
					<main className='relative min-h-screen h-full max-w-screen-xxl w-full flex flex-col items-center m-auto px-2 md:px-3 xl:px-4'>
						<FloatingSidebar />
						<HeroSection />
						<HomeSection />
						<ArticlesLayout triggerRef={ref} />

						{showInputField && (
							<div className='flex flex-col w-full'>
								<FormComponent mode='bottom' className='fixed bottom-8 left-0 px-4 md:px-0 z-40' />
								<div className='fixed bottom-0 left-0 h-[102px] w-full bg-whiteGradient z-10' />
							</div>
						)}
					</main>
				</AppContextProvider>
			</QueryClientProvider>
		</Fragment>
	);
};

export default App;
