import { forwardRef } from 'react';
import { classNames } from '../../utils/classNames';
import { removeMarkdown } from '../../utils/removeMarkdown';

import ExpandButton from './ExpandButton';
import Markdown from './Markdown';

const DesktopTemplateCard = forwardRef(
	(
		{
			isLast,
			maxWidth,
			isExpanded,
			sectionName,
			contactContent,
			isContactSection,
			shouldShowText,
			shouldShowImage,
			image,
			header,
			text,
			displayedText,
			isTruncated,
			toggleCard,
			handleOpenCardPopup,
		},
		ref
	) => {
		return (
			<div
				ref={ref}
				key={isExpanded ? 'expanded' : 'collapsed'}
				className={classNames(
					'w-full h-fit flex flex-col gap-[22px]',
					'p-[18px] rounded-[26px] shadow-cardShadow bg-backgroundColor-secondary',
					maxWidth ? maxWidth : 'w-full md:max-w-[395px]',
					isLast ? 'mb-0' : 'mb-[22px] md:mb-[25px]',
					'transition-all duration-300 ease-in-out z-[80]'
				)}
			>
				<>
					{isContactSection ? (
						<img src={contactContent.img} alt='' className='rounded-2xl' />
					) : (
						image && shouldShowImage && <img src={image} alt='' className='rounded-2xl' />
					)}

					<h4 className='font-bold text-[23px] md:text-[26px] leading-[28px] md:leading-[36px]'>
						{isContactSection ? removeMarkdown(contactContent.header) : removeMarkdown(header)}
					</h4>
				</>

				{text && shouldShowText && (
					<>
						{isContactSection ? (
							<>
								<p className='font-medium text-sm leading-[25px] whitespace-normal'>{contactContent.beforeText}</p>

								<ul className='flex flex-col gap-2 my-3'>
									{contactContent.contacts.map(({ icon, link, name, text }, index) => (
										<li key={index}>
											<a
												href={link}
												target={name !== 'Phone' ? '_blank' : '_self'}
												rel='noopener noreferrer'
												className='text-accent flex items-center gap-2'
											>
												<img src={icon} alt={`${name} Icon`} className='w-[14px] h-[14px]' />

												<div className='flex items-center gap-1'>
													<span className='font-semibold'>{name}:</span>
													<span>{text}</span>
												</div>
											</a>
										</li>
									))}
								</ul>

								<p className='font-medium text-sm'>{contactContent.afterText}</p>
							</>
						) : (
							<span className='font-medium text-sm leading-[25px] whitespace-normal'>
								<Markdown displayedText={displayedText} />
							</span>
						)}
					</>
				)}

				{isTruncated && isExpanded && (
					<button
						className={classNames(
							'w-full h-[30px]',
							'text-xs text-input-placeholder font-bold',
							'ml-auto -mt-4 rounded-[30px] bg-[#E9F0FF]'
						)}
						onClick={() => handleOpenCardPopup(header)}
					>
						Read More
					</button>
				)}

				<div className='flex items-center justify-between'>
					{sectionName && (
						<div className='flex items-center gap-[6px]'>
							<div className='w-[17px] h-[17px] rounded-full bg-accentGradient' />
							<span className='text-[12px] leading-[16px]'>{sectionName}</span>
						</div>
					)}

					<ExpandButton header={header} sectionName={sectionName} isExpanded={isExpanded} toggleCard={toggleCard} />
				</div>
			</div>
		);
	}
);

export default DesktopTemplateCard;
