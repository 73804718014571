import { ReactComponent as HomeIcon } from '../assets/navigation/home.svg';
import { ReactComponent as SolutionsIcon } from '../assets/navigation/solutions.svg';
import { ReactComponent as TechnologiesIcon } from '../assets/navigation/technologies.svg';
import { ReactComponent as ProjectsIcon } from '../assets/navigation/projects.svg';
import { ReactComponent as PartnersIcon } from '../assets/navigation/partners.svg';
import { ReactComponent as ContactIcon } from '../assets/navigation/contact.svg';
import { ReactComponent as CareerIcon } from '../assets/navigation/career.svg';
import { ReactComponent as AboutIcon } from '../assets/navigation/about.svg';

export const NAVIGATION = [
	{
		label: 'Home',
		value: 'home',
		IconComponent: HomeIcon,
	},

	{
		label: 'Solutions',
		value: 'solutions',
		IconComponent: SolutionsIcon,
	},

	{
		label: 'Tech',
		value: 'technologies',
		IconComponent: TechnologiesIcon,
	},

	{
		label: 'Projects',
		value: 'projects',
		IconComponent: ProjectsIcon,
	},

	{
		label: 'VP',
		value: 'value proposition',
		IconComponent: CareerIcon,
	},

	{
		label: 'Partners',
		value: 'partners',
		IconComponent: PartnersIcon,
	},

	{
		label: 'About',
		value: 'about',
		IconComponent: AboutIcon,
	},

	{
		label: 'Contacts',
		value: 'contact',
		IconComponent: ContactIcon,
	},

	// {
	// 	label: 'Career',
	// 	value: 'career',
	// 	IconComponent: CareerIcon,
	// },
];
