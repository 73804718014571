import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { NextUIProvider } from '@nextui-org/system';

import App from './App';
import './styles/global.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<NextUIProvider>
		<App />
	</NextUIProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
