import logoIcon from '../assets/logo.svg';
import { classNames } from '../utils/classNames';

const MobileLogo = ({ logoRef, className }) => {
	return (
		<div ref={logoRef} className={classNames('z-20 select-none', className)}>
			<img src={logoIcon} alt='GENUM LOGO' className='w-[94px] h-[18px]' />
		</div>
	);
};

export default MobileLogo;
