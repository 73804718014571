import logoIcon from '../assets/logo.svg';

const DesktopLogo = ({ logoRef }) => {
	return (
		<div className='fixed pl-8 xl:pl-12 xxl:pl-[60px]'>
			<div ref={logoRef} className='w-fit px-2 py-[14px] select-none z-20'>
				<img src={logoIcon} alt='GENUM LOGO' className='w-[128px] h-[24px]' />
			</div>
		</div>
	);
};

export default DesktopLogo;
