export const getAssistantID = () => {
	// // Получаем текущий URL
	// const url = window.location.href;
	// // Извлекаем ID из URL
	// const id = url.split('/').pop(); // Извлекаем последнюю часть URL

	// if (id) {
	// 	return id;
	// }
	// else {
	// 	return "";
	// }

	return 'asst_ly8ZiBCv21P49zc3eQ4z4Bl3';
};
