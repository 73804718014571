import { classNames } from '../../utils/classNames';

const CircleButton = ({ type = 'button', iconSRC, iconALT, className, handleClick, disabled, ariaLabel }) => {
	return (
		<button
			type={type}
			className={classNames(
				'h-8 md:h-11 w-8 md:w-11 aspect-square',
				'flex items-center justify-center rounded-full',
				className
			)}
			onClick={handleClick}
			disabled={disabled}
			aria-label={ariaLabel || iconALT}
		>
			<img src={iconSRC} alt={iconALT} className='w-[10px] h-[12px] md:w-[14px] md:h-[16px]' />
		</button>
	);
};

export default CircleButton;
