export const removeMarkdown = (text) => {
	if (!text) return '';

	return (
		text
			// Удаление заголовков (# Заголовок)
			.replace(/^#{1,6}\s+/gm, '')
			// Удаление изображений ![alt](url)
			.replace(/!\[.*?\]\(.*?\)/g, '')
			// Удаление ссылок [текст](url)
			.replace(/\[([^\]]+)\]\((.*?)\)/g, '$1')
			// Удаление форматирования **текст** или __текст__
			.replace(/(\*\*|__)(.*?)\1/g, '$2')
			// Удаление форматирования *текст* или _текст_
			.replace(/(\*|_)(.*?)\1/g, '$2')
			// Удаление инлайн-кода `код`
			.replace(/`([^`]+)`/g, '$1')
			// Удаление блоков кода ```код```
			.replace(/```[\s\S]*?```/g, '')
			// Удаление цитат > цитата
			.replace(/^>\s+/gm, '')
			// Удаление списков - элемент или * элемент
			.replace(/^\s*([-*+]|\d+\.)\s+/gm, '')
			// Удаление горизонтальных линий ---
			.replace(/^-{3,}$/gm, '')
			// Удаление таблиц
			.replace(/\|(.+?)\|/g, '')
			// Удаление HTML-тегов
			.replace(/<\/?[^>]+(>|$)/g, '')
			// Удаление лишних символов
			.replace(/\\(\*|_|`|~|\\)/g, '$1')
			// Удаление избыточных пробелов и переносов строк
			.replace(/\s{2,}/g, ' ')
			.replace(/\n{2,}/g, '\n')
			.trim()
	);
};
