import { createRef } from 'react';
import { getArticles } from '../../axios/API';
import { classNames } from '../../utils/classNames';
import { NAVIGATION } from '../../constants/navigation';

const Navigation = ({
	mode,
	setMobileNavigationOpen,
	className,
	navigationPath,
	contentList,
	setContentList,
	availableSectionsList,
	setAvailableSectionsList,
	setSectionLoading,
}) => {
	const moveSection = (sectionId, contentList) => {
		const existingSectionIndex = contentList.findIndex((item) => item.sectionId === sectionId);

		if (existingSectionIndex === -1) return null;

		const updatedContentList = [...contentList];
		const [existingSection] = updatedContentList.splice(existingSectionIndex, 1);

		updatedContentList.push(existingSection);

		return {
			updatedContentList,
			movedSectionRef: existingSection.sectionRef,
		};
	};

	const fetchAndAddSection = async (sectionId, contentList) => {
		const { data } = await getArticles(sectionId);

		const fetchedSection = {
			type: 'section',
			sectionRef: createRef(),
			sectionName: sectionId.charAt(0).toUpperCase() + sectionId.slice(1),
			sectionId: sectionId,
			items: data.block.items,
			questions: data.questions,
		};

		const updatedContentList = [...contentList];
		updatedContentList.push(fetchedSection);

		return {
			fetchedSection,
			updatedContentList,
			newSectionRef: fetchedSection.sectionRef,
		};
	};

	const scrollToSection = (sectionRef) => {
		setTimeout(() => {
			requestAnimationFrame(() => {
				if (sectionRef?.current) {
					sectionRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					});
				}
			});
		}, 100);
	};

	const handleNavigationClick = async (value) => {
		setSectionLoading(true);

		try {
			const existingSectionResult = moveSection(value, contentList);

			if (existingSectionResult) {
				setContentList(existingSectionResult.updatedContentList);
				scrollToSection(existingSectionResult.movedSectionRef);
				setSectionLoading(false);

				return;
			}

			const { updatedContentList, newSectionRef } = await fetchAndAddSection(value, contentList);

			setContentList(updatedContentList);
			setAvailableSectionsList((prev) => prev.filter((element) => element !== value));
			scrollToSection(newSectionRef);
		} catch (error) {
			console.error(error);
		} finally {
			setSectionLoading(false);
			mode === 'mobile' && setMobileNavigationOpen(false);
		}
	};

	// VERSION WITH FETCHING SECTION

	// const handleNavigationClick = async (value) => {
	// 	setSectionLoading(true);

	// 	try {
	// 		const existingSectionResult = moveSection(value, contentList);

	// 		if (existingSectionResult) {
	// 			setContentList(existingSectionResult.updatedContentList);
	// 			scrollToSection(existingSectionResult.movedSectionRef);

	// 			const { fetchedSection } = await fetchAndAddSection(value, contentList);

	// 			await new Promise((resolve) => setTimeout(() => resolve), 0);
	// 			setContentList((prev) => {
	// 				const newState = [...prev];
	// 				const sectionIndex = prev.findIndex(({ sectionId }) => sectionId === value);
	// 				newState[sectionIndex] = fetchedSection;

	// 				return newState;
	// 			});
	// 			setSectionLoading(false);

	// 			return;
	// 		}

	// 		const { updatedContentList, newSectionRef } = await fetchAndAddSection(value, contentList);

	// 		setContentList(updatedContentList);
	// 		setAvailableSectionsList((prev) => prev.filter((element) => element !== value));
	// 		scrollToSection(newSectionRef);
	// 	} catch (error) {
	// 		console.error(error);
	// 	} finally {
	// 		setSectionLoading(false);
	// 		mode === 'mobile' && setMobileNavigationOpen(false);
	// 	}
	// };

	return (
		<ul className={classNames(className)}>
			{NAVIGATION.map(({ label, value, IconComponent }) => {
				const isActive = navigationPath === value;
				const isAvailable = availableSectionsList.includes(value);

				return (
					<li
						key={label}
						onClick={() => handleNavigationClick(value)}
						className={classNames(
							'w-[71px] flex flex-col gap-[10px] items-center justify-between',
							'group transition-all duration-300 ease-in-out cursor-pointer'
						)}
					>
						<IconComponent
							className={classNames(
								'transition-all duration-300 ease-in-out',
								isActive ? 'fill-[#B7D0FF]' : 'fill-white',
								'group-hover:fill-[#B7D0FF]',
								!isAvailable ? 'fill-[#B7D0FF]' : ''
							)}
						/>

						<span className='font-semibold text-[11px] leading-[15px]'>{label}</span>
					</li>
				);
			})}
		</ul>
	);
};

export default Navigation;
