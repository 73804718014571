import { createContext, useContext, useRef, createRef, useState, useEffect } from 'react';

import { NAVIGATION } from '../constants/navigation';

const AppContext = createContext(null);

export const useAppContext = () => useContext(AppContext);

const AppContextProvider = ({ children }) => {
	const scrollStartRef = useRef(null);
	const contentListRef = useRef(null);

	const [availableSectionsList, setAvailableSectionsList] = useState(NAVIGATION.map(({ value }) => value));

	const [sectionRefs, setSectionRefs] = useState(
		NAVIGATION.map(({ value }) => ({
			section: value,
			ref: createRef(),
		}))
	);

	const [navigationPath, setNavigationPath] = useState('');
	const [scrollTimeout, setScrollTimeout] = useState(null);

	const [isRequestPopupOpen, setIsRequestPopupOpen] = useState(false);
	const [mobileNavigationOpen, setMobileNavigationOpen] = useState(false);
	const [mobileNavigationFixed, setMobileNavigationFixed] = useState(false);

	const [queryInput, setQueryInput] = useState('');
	const [contentList, setContentList] = useState([]);
	const [requestRecord, setRequestRecord] = useState([]);

	const [formLoading, setFormLoading] = useState(false);
	const [sectionLoading, setSectionLoading] = useState(false);
	const [questionLoading, setQuestionLoading] = useState(false);
	const [recordingLoading, setRecordingLoading] = useState(false);

	const scrollToBottom = async () => {
		await new Promise((resolve) => setTimeout(resolve, 0));

		window.scrollTo({
			top: document.body.scrollHeight,
			behavior: 'smooth',
		});
	};

	const handleChangeSectionRefs = (section = '') => {
		const existingSectionRef = sectionRefs.find(({ section: existingSection }) => existingSection === section);

		if (existingSectionRef) {
			return existingSectionRef.ref;
		}

		const sectionRef = createRef();

		setSectionRefs((prevRefs) => [
			...prevRefs,
			{
				section,
				ref: sectionRef,
			},
		]);

		return sectionRef;
	};

	const addUserQueryMessage = (query) => {
		setContentList((prevList) => [
			...prevList,
			{
				items: [
					{
						text: query,
					},
				],

				type: 'user-message',
			},
		]);

		scrollToBottom();
	};

	useEffect(() => {
		const handleScroll = () => {
			clearTimeout(scrollTimeout);

			const timeout = setTimeout(() => {
				checkVisibleSections();
			}, 200);

			setScrollTimeout(timeout);
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			clearTimeout(scrollTimeout);
			window.removeEventListener('scroll', handleScroll);
		};
	}, [scrollTimeout]);

	const checkVisibleSections = () => {
		const sections = document.querySelectorAll('[id]');
		const windowHeight = window.innerHeight;
		let currentSection = '';

		sections.forEach((section) => {
			const { top, bottom } = section.getBoundingClientRect();

			if (top < windowHeight && bottom > 0) {
				currentSection = section.id;
			}
		});

		setNavigationPath(currentSection);
	};

	useEffect(() => {
		const storedRequests = localStorage.getItem('genumRequestRecord');
		if (storedRequests) setRequestRecord(JSON.parse(storedRequests));
	}, []);

	const updateRequestRecord = (request) => {
		setRequestRecord((prev) => {
			const isRequestExists = prev.some((item) => item === request);

			if (isRequestExists) {
				return prev;
			}

			const updatedRequests = [...prev, request];
			localStorage.setItem('genumRequestRecord', JSON.stringify(updatedRequests));
			return updatedRequests;
		});
	};

	return (
		<AppContext.Provider
			value={{
				scrollStartRef,
				contentListRef,
				scrollToBottom,

				mobileNavigationOpen,
				setMobileNavigationOpen,
				mobileNavigationFixed,
				setMobileNavigationFixed,
				isRequestPopupOpen,
				setIsRequestPopupOpen,

				sectionRefs,
				setSectionRefs,
				handleChangeSectionRefs,

				addUserQueryMessage,
				availableSectionsList,
				setAvailableSectionsList,

				queryInput,
				setQueryInput,
				contentList,
				setContentList,
				requestRecord,
				setRequestRecord,
				updateRequestRecord,

				navigationPath,
				setNavigationPath,

				formLoading,
				setFormLoading,
				sectionLoading,
				setSectionLoading,
				questionLoading,
				setQuestionLoading,
				recordingLoading,
				setRecordingLoading,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export default AppContextProvider;
