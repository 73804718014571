export const highlightMatch = (searchQuery, text) => {
	if (!searchQuery) return text;

	const parts = text.split(new RegExp(`(${searchQuery})`, 'gi'));

	return parts.map((part, index) =>
		part.toLowerCase() === searchQuery.toLowerCase() ? (
			<span key={index} className='font-bold text-[#57AEFF]'>
				{part}
			</span>
		) : (
			part
		)
	);
};
