import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const useScrollInputVisibility = () => {
	const [showInputField, setShowInputField] = useState(false);
	const [isTriggered, setIsTriggered] = useState(false);
	const scrollRef = useRef(0);

	const { ref, inView } = useInView({
		threshold: 0.3,
		triggerOnce: true,
	});

	useEffect(() => {
		if (inView) {
			setIsTriggered(true);
			scrollRef.current = document.documentElement.scrollTop;
		}
	}, [inView]);

	useEffect(() => {
		const scrollHandler = (event) => {
			if (isTriggered && event.target.documentElement.scrollTop > scrollRef.current) {
				setShowInputField(true);
			} else {
				setShowInputField(false);
			}
		};

		document.addEventListener('scroll', scrollHandler);
		return () => document.removeEventListener('scroll', scrollHandler);
	}, [isTriggered]);

	return { ref, showInputField };
};

export default useScrollInputVisibility;
